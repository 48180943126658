<template>
  <div>
    <v-card class="mb-8">
      <v-card-title>{{'page.accounting-overview'| t}}</v-card-title>
      <v-card-text class="d-flex">
        <div style="width: 220px" class="mr-4">
          <datetime-picker
            placeholder="action.time_range"
            range
            v-model="dateRange"
          ></datetime-picker>

          <div class="body-2">{{dateRangeLimitInvalidMessage}}</div>
        </div>

        <div>
          <v-btn icon @click="loadData">
            <v-icon class="fa fa-sync"></v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <overviewData
      class="mb-8"
      v-if="overview"
      :overview="overview"
    ></overviewData>

    <accountingCharts
      :logs="logs"
      :dateRangeStart="dateRangeStart"
      :dateRangeEnd="dateRangeEnd"
    ></accountingCharts>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  metaInfo() {
    return {
      title: this.$t('page.accounting-overview'),
    }
  },
  data: () => ({
    maxRange: 60, // 最多60天
    dateRange: null,
    overview: null,
    logs: [],
  }),
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const start = this.$dayjs().subtract(30, 'days').format('YYYY-MM-DD')
      const end = this.$dayjs().format('YYYY-MM-DD')
      this.dateRange = [start, end]
    },
    async loadData() {
      if(this.disabled) return
      this.$store.dispatch('loading/progress')
      this.$store.dispatch('loading/active')
      await Promise.all([
        this.loadOverview(),
        this.loadAccountingLogs()
      ])
      this.$store.dispatch('loading/close')
      this.$store.dispatch('loading/closeProgress')
    },
    async loadOverview() {
      try {
        this.overview = await this.$api.collection.accountingApi.overview({
          start_at: this.dateRangeStart,
          end_at: this.dateRangeEnd,
        })
      } catch (error) {
        console.warn(error)
      }
    },
    async loadAccountingLogs() {
      try {
        const result = await this.$api.collection.accountingApi.overviewDaily({
          start_at: this.dateRangeStart,
          end_at: this.dateRangeEnd,
        })
        this.logs = result.data
      } catch (error) {
        console.warn(error)
      }
    },
  },
  computed: {
    disabled() {
      if(!this.dateRangeLimitValid) return true
      return false
    },
    dateRangeStart() {
      if(!Array.isArray(this.dateRange)) return null
      return this.dateRange[0]
    },
    dateRangeEnd() {
      if(!Array.isArray(this.dateRange)) return null
      return this.dateRange[1]
    },
    dateRangeLimitInvalidMessage() {
      return this.$t('date_range.limit', { max: this.maxRange })
    },
    dateRangeLimitValid() {
      if(!this.dateRangeStart || !this.dateRangeEnd) return false
      const diff = this.$dayjs(this.dateRangeEnd).diff(this.dateRangeStart, 'day') + 1
      return diff < this.maxRange
    },
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.loadData()
      },
    }
  },
  components: {
    overviewData: () => import('./accountOverview/overviewData.vue'),
    accountingCharts: () => import('./accountOverview/accountingCharts.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
